.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

:root {
  --color-primary: #B12023;
  --color-primary-hover: #901a1c;
  --color-dark-gray: #212121;
  --color-medium-gray: #626262;
  --color-white: #FFF;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0.3px;
}

a {
  color: var(--color-primary);
}
a:hover {
  color: var(--color-primary-hover);
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

img {
  max-width: 100%;
}

.btn-outline-danger {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.btn-outline-danger:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.btn-xl {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 1.3rem;
}

#topnav img.logo {
  max-width: 255px;
  height: auto;
}
#topnav .navbar-nav .nav-link {
  font-size: 0.9em;
}
#topnav .navbar-nav .nav-link.active {
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  #topnav .navbar-nav .nav-link {
    font-size: 1em;
  }
}

#about {
  border-width: 13px;
  border-top-style: solid;
  border-image: linear-gradient(to right, #212121 2%, #353535 100%) 1;
  background-image: url("../../images/texture-maroon.jpg");
  color: var(--color-white);
  font-weight: 300;
}
#about a {
  color: var(--color-white);
}
#about a:hover {
  color: #d9d9d9;
}
#about img.logo {
  max-height: 75px;
}

#solutions {
  background-image: url("../../images/texture-geometric.jpg");
  color: var(--color-dark-gray);
  padding-top: 4.5em;
  padding-bottom: 5.5em;
}
@media screen and (max-width: 991px) {
  #solutions div.solution-list {
    padding-left: 1.3em;
  }
}

#brands {
  padding-top: 4em;
}
#brands img.brand {
  height: 28px;
}
#brands img.brand.hotair {
  height: 30px;
}
#brands img.brand.pjmedia {
  height: 33px;
}
#brands img.brand.twitchy {
  height: 31px;
}
#brands div.grid-cell {
  font-size: 0.9em;
  margin-bottom: 1.5em;
}
@media screen and (max-width: 767px) {
  #brands div.grid-cell {
    padding-bottom: 0.7em;
    text-align: center;
    margin-bottom: 0.1em;
  }
  #brands div.grid-cell:nth-child(even) {
    margin-bottom: 1em;
  }
}

#media-kit {
  background-image: radial-gradient(circle at center, #464646 2%, #212121 100%);
  padding-top: 5em;
  padding-bottom: 4em;
}
#media-kit img.media-kit {
  max-width: 600px;
}

#logos {
  background-color: #EFEFEF;
}
#logos img.brand {
  height: 30px;
}
#logos img.brand.hotair {
  height: 32px;
}
#logos img.brand.pjmedia {
  height: 35px;
}
#logos img.brand.twitchy {
  height: 33px;
}
@media screen and (max-width: 767px) {
  #logos {
    display: none;
  }
}

footer {
  color: var(--color-dark-gray);
  border-width: 13px;
  border-top-style: solid;
  border-image: linear-gradient(to right, #212121 2%, #353535 100%) 1;
}
footer .footer-links ul {
  list-style-type: none;
}
footer .footer-links ul li {
  padding: 0 1em;
  float: left;
  text-align: center;
}
@media screen and (max-width: 991px) {
  footer .footer-links ul li {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  footer .footer-copyright img.logo {
    max-width: 150px;
    padding-bottom: 1em;
  }
  footer .footer-copyright .text-copyright {
    text-align: center;
  }
}

.fw-500 {
  font-weight: 500;
}